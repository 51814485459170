.form-control {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}
@media (min-width: 768px) {
.form-control {
    margin-left: 15px;
  }
}
.form-control label {
  margin-bottom: 0;
  display: block;
  font-family: Ubuntu;
  font-size: 16px;
  color: #888888;
  letter-spacing: -0.62px;
  margin-right: 0.5rem;
}
@media (min-width: 768px) {
  .form-control label {
    font-size: 18px;
  }
}

.form-control div {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .form-control input {
    flex: 0 0 60%;
  }
}
.form-control input {
  font: inherit;
  padding: 0.25rem;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
  width: 13rem;
  max-width: 100%;
  font-size: 18px;
}
