.forecast-tabs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.forecast-tabs .active {
  background-image: linear-gradient(180deg, #522bff 0%, #890eff 100%);
  border-radius: 4px;
  color: #fff;
}

.forecast-tabs .day-tab {
  flex: 0 0 25%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .forecast-tabs .day-tab {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 10px;
  }
}

.forecast-tabs .day-tab.active > div {
  color: #fff;
}

.day-tab {
  padding: 0;
}
.day-tab > div {
  margin-bottom: 10px;
}